document.getElementById("btnContact").addEventListener("click", function(e) {
    var form = document.querySelector("form");
    if (!form.checkValidity()) {
        alert("Por favor complete todos los campos");
    } else {
        var data = new FormData();
        data.append('email', form[0].value);
        data.append('mensaje', form[1].value);
        activeLoader(true);
        ajax("POST", "/server/email.php", data)
        .then(content => {
            if (content == "0") {
                alerta("Alerta", "Mensaje enviado corréctamente");
            } else {
                alerta("Error", "No se ha podido enviar el mensaje.");
            }
            form.reset();
            enableSendButton(false);
            activeLoader(false); 
        })
        .catch(err => {
            activeLoader(false);
            alerta("Error", "No se ha podido enviar el mensaje.");
        });
    }
});

document.getElementById("checkbox").addEventListener("change", function() {
    if (this.checked) {
        var elem = document.getElementById('modal1');
        var instance = M.Modal.getInstance(elem);
        instance.open();
        enableSendButton(true);
    } else {
        enableSendButton(false);
    }
});

function enableSendButton(enable) {
    var contactButton = document.getElementById("btnContact");
    if (enable) {
        contactButton.classList.remove("disabled");
    } else {
        contactButton.classList.add("disabled");
    }
}

function activeLoader(show) {
    var loader = document.getElementById("contactLoader");
    if (show) {
        loader.classList.add("active");
    } else {
        loader.classList.remove("active");
    }
}

function ajax(verb, url, data) {
    return new Promise((resolve, reject) => {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState !== 4) return;
            if (this.status == 200) {
                resolve(this.responseText);
            } else {
                reject(new Error("Error en la petición"));
            }
        };
        xhttp.open(verb, url, true);
        xhttp.send(data);
    });
}

function alerta(titulo, contenido) {
    var elem = document.getElementById('modal2');
    elem.querySelector("h4").innerHTML = titulo;
    elem.querySelector("p").innerHTML = contenido;
    var instance = M.Modal.getInstance(elem);
    instance.open();
}