// window.addEventListener('scroll', function () {
//     console.log("Scrollin'");
// });

var scroll =
  window.requestAnimationFrame ||
  function(callback) {
    window.setTimeout(callback, 1000 / 60);
  };

function loop() {
  setupAnimationScroll(".show-on-scroll", "is-visible");
  scroll(loop);
}

function setupAnimationScroll(name, animateClass) {
  document.querySelectorAll(name).forEach(function(element) {
    if (isElementInViewport(element)) {
      element.classList.add(animateClass);
    } else {
      element.classList.remove(animateClass);
    }
  });
}

loop();

// Helper function from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
  // special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect();
  return (
    (rect.top <= 0 && rect.bottom >= 0) ||
    (rect.bottom >=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.top <=
        (window.innerHeight || document.documentElement.clientHeight)) ||
    (rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight))
  );
}

//   function elementInCenter(element) {
//     var scroll = $(window).scrollTop();
//     var alturaTop = $(element).offset().top;
//     var alturamedia = $(window).height() / 2;
//     return (scroll >= alturaTop - alturamedia);
//   },
//   function elementOutCenter(element) {
//     var scroll = $(window).scrollTop();
//     var alturaBottom = $(element).offset().top + $(element).height();
//     var alturamedia = $(window).height() / 2;
//     return (scroll >= alturaBottom - alturamedia);
//   }

//   $(window).scroll(function () {
//     if (Utils.elementInCenter("#team")) {
//       $("#fraseteam").show();
//       $("#fraseteam").addClass("fraseteam");
//       $("#logoteam").addClass("logoteam");
//       $("#logoteam").show();
//     }

//     if (Utils.elementInCenter("#quienes-somos-1")) {
//       $("#quienes-somos-1 .layer-dark").fadeTo("1000", 0.7, function () {
//         $("#quienes-somos-1 .letra-quienes-somos").fadeIn(1000);
//       });
//     }

//     if (Utils.elementInCenter("#quienes-somos-2")) {
//       $("#quienes-somos-2 .layer-dark").fadeTo("1000", 0.7, function () {
//         $("#quienes-somos-2 .letra-quienes-somos").fadeIn(1000)
//       });
//     }

//   });

// });
